import React, { useState, useEffect, useRef } from "react";
import qs from 'qs';
import axios from "axios";
import { useLoading } from "../context/LoadingContext";
import { Helmet } from "react-helmet";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const ErrorComponent = ({ errorMessage = "" }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="px-10 my-10 md:px-20 font-bai sm:my-20">
      <div className="max-w-2xl mx-auto p-8 bg-gray-100 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-6 text-gray-800">Oops! Something went wrong. We apologize for the inconvenience.</h1>
        <p className="text-lg text-gray-800 mb-6">{errorMessage}</p>
        <button
          onClick={handleGoBack}
          className="text-lg text-black underline cursor-pointer"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

const Form = () => {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const location = useLocation();
  const { hash, pathname, search } = location;

  const paths = pathname.split('/');
  const formId = paths[paths.length - 1];

  const { loading, setLoading } = useLoading();

  const portalId = "45645086";
  const formGuid = "85392b22-66ed-48e3-a63d-7dde9cfbb89c";

  const getUserIP = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error("Failed to fetch the user's IP address:", error);
      return null;
    }
  };

  const getHubSpotCookie = () => {
    const name = 'hubspotutk=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(';');

    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.startsWith(name)) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchFormFields = async () => {
      try {
        const response = await axios.get(`/api/getFormData?formGuid=${formId}&portalId=${portalId}`);
        setFormFields(response.data.formFields);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch form fields. Please try again later.");
        setLoading(false);
      }
    };

    setLoading(true);
    fetchFormFields();
  }, [formId, portalId]);

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join('-');
    }
    return value;
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePhoneNumber = (phone) => /^\d{3}-\d{3}-\d{4}$/.test(phone);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = name === "phone" ? formatPhoneNumber(value) : value;
    setFormValues((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const currentErrors = { ...errors };

    if (name === "email" && !validateEmail(value)) {
      currentErrors.email = "Please enter a valid email address.";
    } else if (name === "phone" && value && !validatePhoneNumber(value)) {
      currentErrors.phone = "Please enter a valid phone number (e.g. 123-123-1234).";
    } else {
      delete currentErrors[name];
    }

    setErrors(currentErrors);
  };

  const handleKeyUp = (e) => {
    // if (e.key === 'Enter') {
    //   handleSubmit(e);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setSubmissionStatus(null);
    setError(null);

    const currentErrors = {};
    let hasErrors = false;

    formFields.forEach((field) => {
      const value = formValues[field.name] || "";

      if (field.required && !value) {
        currentErrors[field.name] = `${field.label} field is required.`;
        hasErrors = true;
      }

      // Check email format
      if (field.name === "email" && value && !validateEmail(value)) {
        currentErrors.email = "Please enter a valid email address.";
        hasErrors = true;
      }

      // Check phone number format
      if (field.name === "phone" && value && !validatePhoneNumber(value)) {
        currentErrors.phone = "Please enter a valid phone number (e.g. 123-123-1234).";
        hasErrors = true;
      }
    });

    // If there are errors, prevent form submission
    if (hasErrors) {
      setErrors(currentErrors);
      return;
    }

    // Reset errors if no errors found
    setErrors({});

    setLoading(true);

    const formData = new FormData(formRef.current);
    const formEntries = Object.fromEntries(formData.entries());

    const userIP = await getUserIP();
    const hutk = getHubSpotCookie();

    const context = {
      hutk: hutk,
      ipAddress: userIP,
      pageUri: window.location.href,
      pageName: document.title,
    };

    const encodedFormData = qs.stringify({
      ...formEntries,
      hs_context: JSON.stringify(context),
    });

    const hubspotEndpoint = `https://forms.hubspot.com/uploads/form/v2/${portalId}/${formGuid}`;

    try {
      await axios.post(hubspotEndpoint, encodedFormData, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      setSubmitted(true);
      setSubmissionStatus("Success! Your information has been submitted.");
    } catch (error) {
      setSubmissionStatus("There was an error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div></div>;
  if (error) return <ErrorComponent errorMessage="Cannot load form. Please try again later." />;

  return (
    <div className="px-10 my-10 md:px-20 font-bai sm:my-20">
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="max-w-2xl mx-auto p-8 bg-gray-100 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-6 text-center">
          {submitted ? "Thank you" : "Contact Us"}
        </h1>

        {submitted ? (
          <div className="text-center">
            <p className="text-lg">
              Success! Your information has been submitted. We appreciate your interest, and one of our representatives
              will be in touch with you within 3-5 business days.
            </p>
          </div>
        ) : (
          <form ref={formRef} onKeyUp={handleKeyUp} className="space-y-4">
            {formFields.map((field, index) => (
              <div key={index}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  <span dangerouslySetInnerHTML={{ __html: field.label }} /> {/* Correctly rendering HTML */}
                  {field.required && <span className="text-red-500">*</span>}
                </label>
                {field.name === "email" ? (
                  <input
                    type="email"
                    name="email"
                    value={formValues.email || ""}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder={field.placeholder}
                    required={field.required}
                    className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                ) : field.name === "phone" ? (
                  <input
                    type="tel"
                    name="phone"
                    value={formValues.phone || ""}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder={field.placeholder}
                    className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                ) : field.fieldType === "select" ? (
                  <select
                    name={field.name}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    required={field.required}
                    className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <option value="">{field.placeholder}</option>
                    {field.options.map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : field.fieldType === "textarea" ? (
                  <textarea
                    name={field.name}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder={field.placeholder}
                    required={field.required}
                    className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                ) : (
                  <input
                    type={field.fieldType}
                    name={field.name}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder={field.placeholder}
                    required={field.required}
                    className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                )}
                {errors[field.name] && (
                  <div
                    className="text-red-500 text-sm mt-1"
                    dangerouslySetInnerHTML={{ __html: errors[field.name] }}
                  ></div>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={handleSubmit}
              className={`w-full bg-black text-white py-2 rounded-lg transition duration-200 ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700"
                }`}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        )}

        {!submitted && submissionStatus && (
          <div className="mt-4 text-center">
            <p className={`text-lg ${submissionStatus.startsWith("Success") ? "text-green-500" : "text-red-500"}`}>
              {submissionStatus}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
